import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../service/api";
import { logout, getUser, isLoggedIn } from "../../service/auth";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { RiCalendarCheckLine } from "react-icons/ri";
import { LuCalendarClock } from "react-icons/lu";
import { MdOutlineNotificationsNone } from "react-icons/md";
import "./animationsActive.css";

import { ReactComponent as Dashboard } from "../../assets/dashboard.svg";
import {
  BsChevronDown,
  BsBell,
  BsFillBriefcaseFill,
  BsFileEarmarkMinus,
  BsFiles,
} from "react-icons/bs";
import {
  FcNegativeDynamic,
  FcConferenceCall,
  FcReading,
  FcApproval,
} from "react-icons/fc";
import {
  FaUserPlus,
  FaDraftingCompass,
  FaUserEdit,
  FaFileSignature,
} from "react-icons/fa";
import { HiSquare3Stack3D, HiListBullet } from "react-icons/hi2";
import { FaAtlassian } from "react-icons/fa6";
import { SlUserFollowing } from "react-icons/sl";
import { FiLayers } from "react-icons/fi";
import { TbReport } from "react-icons/tb";
import { PiFilesBold } from "react-icons/pi";
import { MdOutlineAccessTime } from "react-icons/md";

// import { PiFilesLight } from "react-icons/"
// import * as styles from "../styles/layout/nav.module.scss";

const Nav = (props) => {
  const [openSection, setOpenSection] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();


  const isRouteActive = (to) => {
    return location.pathname === to;
  };

  const toggleSection = (section) => {
    if (openSection === section) {
      setOpenSection(null); // Close the section if it's already open
    } else {
      setOpenSection(section);
    }
  };

  const ProjectsIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      fill="#FCF3F3"
      className="w-6 h-6 mr-4"
    >
      <path d="M427.84 380.67l-196.5 97.82a18.6 18.6 0 0 1-14.67 0L20.16 380.67c-4-2-4-5.28 0-7.29L67.22 350a18.65 18.65 0 0 1 14.69 0l134.76 67a18.51 18.51 0 0 0 14.67 0l134.76-67a18.62 18.62 0 0 1 14.68 0l47.06 23.43c4.05 1.96 4.05 5.24 0 7.24zm0-136.53l-47.06-23.43a18.62 18.62 0 0 0-14.68 0l-134.76 67.08a18.68 18.68 0 0 1-14.67 0L81.91 220.71a18.65 18.65 0 0 0-14.69 0l-47.06 23.43c-4 2-4 5.29 0 7.31l196.51 97.8a18.6 18.6 0 0 0 14.67 0l196.5-97.8c4.05-2.02 4.05-5.3 0-7.31zM20.16 130.42l196.5 90.29a20.08 20.08 0 0 0 14.67 0l196.51-90.29c4-1.86 4-4.89 0-6.74L231.33 33.4a19.88 19.88 0 0 0-14.67 0l-196.5 90.28c-4.05 1.85-4.05 4.88 0 6.74z" />
    </svg>
  );

  const handleLogout = () => {
    logout(() => {
      navigate("/", { replace: true });
    });
  };

  return (
    <nav className="h-full bg-[#0e0e0e] text-grey-100 py-16 px-8 border-r-4">
      <ul className="space-y-12 uppercase">
        <div>
          <Link
            className={`text-white text-opacity-50 text-xs flex items-center relative ${
              isRouteActive("/dashboard")
                ? "active-tab-text font-bold text-blue-300 animate-blink"
                : ""
            }`}
            to="/dashboard"
          >
            {isRouteActive("/dashboard") && (
              <div className="absolute top-0 right-0 h-full w-0.5 bg-teal-500"></div>
            )}
            <Dashboard className="w-6 h-6 mr-4" />
            Dashboard
          </Link>
        </div>
        {props.isAdmin || props.isStakeholders ? (
          <li>
            <div
              className="flex text-white text-opacity-50 text-xs items-center justify-between"
              onClick={() => toggleSection("manageEmployees")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 640 512"
                className="w-9 h-9 mr-4 text-blue-50"
              >
                <path
                  d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"
                  fill="currentColor"
                />
              </svg>
              Manage Employees
              <div className="ml-4 text-white">
                <BsChevronDown
                  className={`cursor-pointer ${
                    openSection === "manageEmployees"
                      ? "transform rotate-180"
                      : ""
                  }`}
                />
              </div>
            </div>

            {openSection === "manageEmployees" && (
              <ul className="ml-4 text-sm gap-x-4 p-1 px-1">
                <li>
                  <Link
                    className="text-white text-opacity-50 text-xs flex items-center pb-4"
                    to="/employee"
                    activeClassName="text-opacity-100"
                  >
                    <FaUserPlus className="w-5 h-5 mr-3 text-blue-200" />
                    Add Employee
                  </Link>
                </li>
              </ul>
            )}
            {openSection === "manageEmployees" && (
              <ul className="ml-4 text-sm gap-x-4 p-1 px-1">
                <li>
                  <Link
                    className="text-white text-opacity-50 text-xs flex items-center"
                    to="/manageEmployees"
                    activeClassName="text-opacity-100"
                  >
                    <FaUserEdit className="w-5 h-5 mr-3 text-grey-100" />
                    View Employee
                  </Link>
                </li>
              </ul>
            )}
            {/* {openSection === "manageEmployees" && (
              <ul className="ml-4 text-sm gap-x-4 p-3 px-1">
                <li>
                  <Link
                    className="text-white text-opacity-50 text-xs flex items-center"
                    to="/deletedEmployees"
                    activeClassName="text-opacity-100"
                  >
                    <AiOutlineUserDelete className="w-8 h-8 mr-4 text-red-300" />
                    Arbitrary Employee
                  </Link>
                </li>
              </ul>
            )} */}
          </li>
        ) : (
          <></>
        )}
        {props.isProd ||
        props.isTL ||
        props.isLogistics ||
        props.isStakeholders ? (
          <li>
            <div
              className={`flex text-white text-opacity-50 text-xs items-center ${
                isRouteActive("/files/repub/production")
                  ? // isRouteActive("/files/QAProduction") ||
                    // isRouteActive("/files/finalProduction")
                    "active animate-blink font-bold text-blue-300"
                  : ""
              }`}
              onClick={() => toggleSection("projects")}
            >
              {/* <BsFillBriefcaseFill className="w-4 h-4 mr-4" /> */}
              {ProjectsIcon}
              Production
              <div className="ml-6 text-white">
                <BsChevronDown
                  className={`cursor-pointer ${
                    openSection === "projects" ? "transform rotate-180" : ""
                  }`}
                />
                {/* <BsChevronDown
                  className={`cursor-pointer ${
                    isSubMenuOpen ? "transform rotate-180" : ""
                  }`} */}

                {/* /> */}
              </div>
            </div>
            {openSection === "projects" && (
              <ul className="ml-6 text-sm gap-x-4 pt-6 px-2">
                <li>
                  <Link
                    className={`text-white text-opacity-50 text-xs flex items-center ${
                      isRouteActive("/files/repub/production")
                        ? "active-tab-text font-bold text-blue-300 animate-blink"
                        : ""
                    }`}
                    to="/files/repub/production"
                    activeClassName="text-opacity-100"
                  >
                    <BsFiles className="w-5 h-5 mr-2" />
                    Repub
                  </Link>
                </li>
                {/* <li>
                  <Link
                    className={`text-white text-opacity-50 text-xs flex items-center pb-6 ${
                      isRouteActive("/files/QAProduction")
                        ? "active-tab-text font-bold text-blue-300 animate-blink"
                        : ""
                    }`}
                    to="/files/QAProduction"
                    activeClassName="text-opacity-100"
                  >
                    <FcReading className="w-4 h-4 mr-2" />
                    QA
                  </Link>
                </li>
                <li>
                  <Link
                    className={`text-white text-opacity-50 text-xs flex items-center ${
                      isRouteActive("/files/finalProduction")
                        ? "active-tab-text font-bold text-blue-300 animate-blink"
                        : ""
                    }`}
                    to="/files/finalProduction"
                    // activeClassName="text-opacity-100"
                  >
                    <FcApproval className="w-4 h-4 mr-2" />
                    Finalize
                  </Link>
                </li> */}
              </ul>
            )}
            {openSection === "projects" && (
              <ul className="ml-6 text-sm gap-x-4 pt-6 px-2">
                <li>
                  <Link
                    className={`text-white text-opacity-50 text-xs flex items-center ${
                      isRouteActive("/files/epub/production")
                        ? "active-tab-text font-bold text-blue-300 animate-blink"
                        : ""
                    }`}
                    to="/files/epub/production"
                    activeClassName="text-opacity-100"
                  >
                    <PiFilesBold className="w-6 h-6 mr-2" />
                    Epub
                  </Link>
                </li>
              </ul>
            )}
          </li>
        ) : (
          <></>
        )}
        {props.isStakeholders || props.isLogistics || props.isTL ? (
          <li>
            <div
              className={`flex text-white text-opacity-50 text-xs items-center justify-between relative ${
                isRouteActive("/repub/FileAllocation") ||
                isRouteActive("/repub/deliveredFiles")
                  ? "active animate-blink font-bold text-blue-300"
                  : ""
              }`}
              onClick={() => toggleSection("allocations")}
            >
              <FaAtlassian className="w-4 h-4 mr-4" />
              Allocation
              <div className="ml-6 text-white">
                <BsChevronDown
                  className={`cursor-pointer ${
                    openSection === "allocations" ? "transform rotate-180" : ""
                  }`}
                  // onClick={toggleSubMenu}
                />
                {/* {(isRouteActive("/repub/FileAllocation") ||  isRouteActive("/repub/deliveredFiles")) && (
            <div className="absolute top-0 right-0 h-full w-0.5 bg-teal-500"></div>
          )} */}
              </div>
            </div>
            {openSection === "allocations" && (
              <ul className="ml-8 text-sm gap-x-4 p-6 px-2">
                <li>
                  <Link
                    className={`text-white text-opacity-50 text-xs flex items-center ${
                      isRouteActive("/repub/FileAllocation")
                        ? "active-tab-text font-bold text-blue-300 animate-blink"
                        : ""
                    }`}
                    to="/repub/FileAllocation"
                    // activeClassName="text-opacity-100"
                  >
                    <HiSquare3Stack3D className="w-4 h-4 mr-2" />
                    Repub
                  </Link>
                </li>
                <li className="mt-3 ml-4">
                  <Link
                    className={`text-white text-opacity-50 text-xs flex items-center ${
                      isRouteActive("/repub/deliveredFiles")
                        ? "active-tab-text font-bold text-blue-300 animate-blink"
                        : ""
                    }`}
                    to="/repub/deliveredFiles"
                    // activeClassName="text-opacity-100"
                  >
                    <HiListBullet className="w-4 h-4 mr-2" />
                    Delivered
                  </Link>
                </li>
                <li className="mt-4 mr-2">
                  <Link
                    className={`text-white text-opacity-50 text-xs flex items-center ${
                      isRouteActive("/epub/FileAllocation")
                        ? "active-tab-text font-bold text-blue-300 animate-blink"
                        : ""
                    }`}
                    to="/epub/FileAllocation"
                    // activeClassName="text-opacity-100"
                  >
                    <FiLayers className="w-4 h-4 mr-2" />
                    Epub
                  </Link>
                </li>
                <li className="mt-3 ml-4">
                  <Link
                    className="text-white text-opacity-50 text-xs flex items-center"
                    to="/epub/deliveredFiles"
                    activeClassName="text-opacity-100"
                  >
                    <HiListBullet className="w-4 h-4 mr-2" />
                    Delivered
                  </Link>
                </li>
              </ul>
            )}
          </li>
        ) : (
          <></>
        )}
        {/* {props.isLogistics && (
          <li>
          <Link
            className={`text-white text-opacity-50 text-xs flex items-center ${
              isRouteActive("/projectsReport")
                ? "active-tab-text text-blue-300 animate-blink"
                : ""
            }`}
            to="/projectsReport"
            // activeClassName="text-opacity-100"
          >
            <TbReport className="w-5 h-5 mr-4 text-pink-100" />
            Reports
          </Link>
        </li>
           )} */}
        {props.isAdmin || props.isStakeholders ? (
          <li>
            <Link
              className={`text-white text-opacity-50 text-xs flex items-center relative ${
                isRouteActive("/employeeActivity")
                  ? "active-tab-text text-blue-300 animate-blink"
                  : ""
              }`}
              to="/employeeActivity"
              // activeClassName="text-opacity-100"
            >
              {isRouteActive("/employeeActivity") && (
                <div className="absolute top-0 right-0 h-full w-0.5 bg-teal-500"></div>
              )}
              <RiCalendarCheckLine className="w-5 h-5 mr-3 text-blue-100" />
              Attendance
            </Link>
          </li>
        ) : (
          <></>
        )}

        {props.isAdmin || props.isStakeholders || getUser().email === "logistics@patelinfo.com"  ? (
          <li>
            <div
              className="flex text-white text-opacity-50 text-xs items-center justify-between"
              onClick={() => toggleSection("shiftManagement")}
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 640 512"
                className="w-9 h-9 mr-4 text-blue-50"
              >
                <path
                  d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"
                  fill="currentColor"
                />
              </svg> */}
              <LuCalendarClock className="w-6 h-6 mr-3 text-blue-100" />
              Shift Management
              <div className="ml-4 text-white">
                <BsChevronDown
                  className={`cursor-pointer ${
                    openSection === "shiftManagement"
                      ? "transform rotate-180"
                      : ""
                  }`}
                />
              </div>
            </div>

            {openSection === "shiftManagement" && (
              <ul className="ml-2 text-sm gap-x-4 p-3 px-2">
                <li>
                  <Link
                    className="text-white text-opacity-50 text-xs flex items-center"
                    to="/shift/roster"
                    activeClassName="text-opacity-100"
                  >
                    <MdOutlineAccessTime className="w-4 h-4 mr-3 text-blue-200" />
                    Shift
                  </Link>
                </li>
              </ul>
            )}
            {/* {openSection === "manageEmployees" && (
              <ul className="ml-4 text-sm gap-x-4 p-3 px-1">
                <li>
                  <Link
                    className="text-white text-opacity-50 text-xs flex items-center"
                    to="/deletedEmployees"
                    activeClassName="text-opacity-100"
                  >
                    <AiOutlineUserDelete className="w-8 h-8 mr-4 text-red-300" />
                    Arbitrary Employee
                  </Link>
                </li>
              </ul>
            )} */}
          </li>
        ) : (
          <></>
        )}

        {/* {props.isAdmin && (
            <li>
            <Link
              className={`text-white text-opacity-50 text-xs flex items-center ${
                isRouteActive("/adminReport")
                  ? "active-tab-text text-blue-300 animate-blink"
                  : ""
              }`}
              to="/adminReports"
              // activeClassName="text-opacity-100"
            >
              <TbReport className="w-5 h-5 mr-4 text-pink-100" />
              Report
            </Link>
          </li>
        )} */}
        {/* {props.isLogistics || props.isTL || props.isProd ? (
          <div>
            <Link
              className="text-white text-opacity-50 text-xs flex items-center"
              to="/notification"
              activeClassName="text-opacity-100"
            >
              <BsBell className="w-4 h-4 mr-4" />
              Notifications
            </Link>
          </div>
        ) : (
          <></>
        )} */}
        <li>
          <Link
            className={`text-white text-opacity-50 text-xs flex items-center relative ${
              isRouteActive("/notification")
                ? "active-tab-text font-bold text-blue-300 animate-blink"
                : ""
            }`}
            to="/notification"
            // activeClassName="text-opacity-100"
          >
            {isRouteActive("/notification") && (
              <div className="absolute top-0 right-0 h-full w-0.5 bg-teal-500"></div>
            )}
            <MdOutlineNotificationsNone className="w-6 h-6 mr-2 text-white" />
            notifications
            {/* {notificationCount > 0 && (
              <span className="ml-1 text-sm bg-green-500 text-white rounded-full px-2">
                {notificationCount}
              </span>
            )} */}
          </Link>
        </li>
        <li>
          <Link
            className={`text-white text-opacity-50 text-xs flex items-center relative ${
              isRouteActive("/profile")
                ? "active-tab-text font-bold text-blue-300 animate-blink"
                : ""
            }`}
            to="/profile"
            // activeClassName="text-opacity-100"
          >
            {isRouteActive("/profile") && (
              <div className="absolute top-0 right-0 h-full w-0.5 bg-teal-500"></div>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 512 512"
              fill="#FCF3F3" // Set the fill color to #FCF3F3
              className="w-5 h-5 mr-4" // Adjust the width and height as neede
            >
              <path d="M406.5 399.6C387.4 352.9 341.5 320 288 320H224c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3h64c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z" />
            </svg>
            About Me
          </Link>
        </li>
        <button
          className="flex items-center text-gray-300 hover:text-blue-200 font-bold md:text-lg lg:text-xl font-sans"
          onClick={handleLogout}
        >
          <RiLogoutCircleRLine className="text-2xl mt-1 md:mr-1 lg:mr-2" />
          Logout
        </button>
      </ul>
    </nav>
  );
};

export default Nav;
